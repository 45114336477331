import moment from 'moment-timezone'

import { DEFAULT_TIME_ZONE_NAME } from '~/src/components/shared/features/constants'

import { prettyTimeFromIso, isoTimeFromObj, prettyTimeFromObj } from '~/helpers/time'

type DateTimeFormatOptions = {
  weekday?: 'long' | 'short' | 'narrow'
  timeZoneName?:
    | 'long'
    | 'short'
    | 'shortOffset'
    | 'longOffset'
    | 'shortGeneric'
    | 'longGeneric'
}

const weekDaysDictionary: { [key: string]: string | undefined } = {
  Sunday: 'Su',
  Monday: 'M',
  Tuesday: 'T',
  Wednesday: 'W',
  Thursday: 'Th',
  Friday: 'F',
  Saturday: 'Sa',
}

const prettyDateFromIso = (isoString: string | null, excludeYear = false): string => {
  if (!isoString) return ''

  const [year, month, day] = isoString.split(/[^0-9]/)
  return excludeYear ? [month, day].join('/') : [month, day, year].join('/')
}

const prettyDateTimeFromIso = (
  isoString: string | null,
  excludeYear = false
): string => {
  if (!isoString) return ''

  return `${prettyTimeFromIso(isoString)} ${prettyDateFromIso(isoString, excludeYear)}`
}

const prettyDateFromObj = (date: Date, excludeYear = false): string => {
  const year = date.getFullYear()
  let month = '' + (date.getMonth() + 1)
  let day = '' + date.getDate()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return excludeYear ? [month, day].join('/') : [month, day, year].join('/')
}

/**
 * Helper to convert date object to a format which includes weekday and time zone: [Week day] [Time] [Time Zone]
 *
 * @example
 * 2023-02-02 04:09:46.336667 => M 08:30 CT
 */
const prettyDateTimeFromObj = (
  date: Date,
  timeZone = true,
  options: DateTimeFormatOptions = { weekday: 'long', timeZoneName: 'short' }
): string => {
  const localeTimeStringDate = date.toLocaleTimeString('en-us', options).split(' ')

  const weekDay = localeTimeStringDate[0].replace(',', '')
  const tz = localeTimeStringDate[3]

  const result = `${
    options.weekday === 'long' ? weekDaysDictionary[weekDay] : weekDay
  } ${prettyTimeFromObj(date)}`
  return timeZone ? `${result} ${tz}` : result
}

/**
 * Helper to convert date to provided timezone and return the formatted date
 * @param date - date to convert
 * @param timeZoneName  - timezone to convert to
 * @returns
 */
const convertToTimezone = (date: string, timeZoneName: string): string => {
  return moment(date).tz(timeZoneName).format('MM/DD/YYYY HH:mm')
}

/**
 * Helper to convert date to UTC from local time
 * @param time  - time to convert
 * @param timeZoneName - timezone to convert from
 * @returns
 */
const convertBackToUtc = (time: string, timeZoneName: string | undefined) => {
  const editedLocalMoment = moment.tz(
    time,
    'MM/DD/YYYY HH:mm',
    timeZoneName ?? DEFAULT_TIME_ZONE_NAME
  )
  const backToUtc = editedLocalMoment.utc().format()

  return backToUtc
}

// Returns only the date portion in ISO format
const isoDateFromPretty = (pretty: string): string => {
  if (!pretty) return ''

  const [month, day, year] = pretty.split('/')
  return [year, month, day].join('-')
}

// Returns only the date portion in ISO format
const isoDateFromObj = (date: Date): string => {
  const year = date.getFullYear()
  let month = '' + (date.getMonth() + 1)
  let day = '' + date.getDate()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

// Returns ISO format without specifying TZ
const isoDateTimeFromObj = (date: Date): string =>
  `${isoDateFromObj(date)}T${isoTimeFromObj(date)}`

const partsFromIso = (
  isoString: string | null
): {
  year: string | null
  month: string | null
  day: string | null
  hours: string | null
  minutes: string | null
  seconds: string | null
} => {
  if (!isoString) {
    return {
      year: null,
      month: null,
      day: null,
      hours: null,
      minutes: null,
      seconds: null,
    }
  }

  const [year, month, day, hours, minutes, seconds] = isoString.split(/[^0-9]/)
  return { year, month, day, hours, minutes, seconds }
}

const parseDate = (
  date: string
): { month: number | null; day: number | null; year: number | null } => {
  if (!date) return { month: null, day: null, year: null }

  let month, day, year

  if (date.includes('/')) {
    ;[month, day, year] = date.replace(/[^0-9/]/g, '').split('/')
  } else if (date.includes('-')) {
    ;[month, day, year] = date.replace(/[^0-9-]/g, '').split('-')
  } else {
    const onlyNumbers = date.replace(/[^0-9]/g, '')
    const numDigits = onlyNumbers.length

    if (numDigits < 5) {
      month = onlyNumbers.substring(numDigits - 4, numDigits - 2)
      day = onlyNumbers.substring(numDigits - 2, numDigits)
    } else if (numDigits < 7) {
      month = onlyNumbers.substring(numDigits - 6, numDigits - 4)
      day = onlyNumbers.substring(numDigits - 4, numDigits - 2)
      year = onlyNumbers.substring(numDigits - 2, numDigits)
    } else {
      month = onlyNumbers.substring(numDigits - 8, numDigits - 6)
      day = onlyNumbers.substring(numDigits - 6, numDigits - 4)
      year = onlyNumbers.substring(numDigits - 4, numDigits)
    }
  }

  month = parseInt(month)
  day = parseInt(day)
  year = year ? parseInt(year) : new Date().getFullYear()

  if (year < 1000) year += 2000

  if (!month || !day || month > 12 || day > 31) {
    // month or day is missing/invalid, parsing most likely failed, reject whole date
    month = null
    day = null
    year = null
  }

  return { month, day, year }
}

// https://stackoverflow.com/a/54127122
const convertTimezone = (date: Date | string, newTz: string): Date => {
  return date instanceof Date
    ? new Date(date.toLocaleString('en-US', { timeZone: newTz }))
    : new Date(new Date(date).toLocaleString('en-US', { timeZone: newTz }))
}

const isWeekday = (d: Date) => ![0, 6].includes(d.getDay())

const getDaysInMonth = (d: Date) =>
  32 - new Date(d.getFullYear(), d.getMonth(), 32).getDate()

const getNumWeekdaysInMonth = (date: Date, excludeFuture = false): number => {
  const nowMS = new Date().getTime()
  const numDays = getDaysInMonth(date)
  let weekdays = 0

  for (let i = 0; i < numDays; i++) {
    const d = new Date(date.getFullYear(), date.getMonth(), i + 1)

    if (!(excludeFuture && d.getTime() > nowMS) && isWeekday(d)) {
      weekdays++
    }
  }

  return weekdays
}

const addDays = (d: Date, numDays: number): Date =>
  new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate() + numDays,
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
    d.getMilliseconds()
  )

const getStartOfWeek = (date = new Date()): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay())

const getDateRanges = ({
  numDates,
  daysInbetween,
  start = new Date(),
}: {
  numDates: number
  daysInbetween: number
  start?: Date
}): Date[] =>
  Array.from(Array(numDates).keys()).map(
    (i) =>
      new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate() + i * daysInbetween
      )
  )

const isToday = (date: Date): boolean =>
  prettyDateFromObj(date) === prettyDateFromObj(new Date())

const diffInSeconds = (d1: Date, d2: Date): number =>
  (d1.getTime() - d2.getTime()) / 1000

const isDateInPast = (date: Date | string): boolean => {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return date.getTime() < new Date().getTime()
}
const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

/**
 * Helper to combine the date for one Date with the time of another Date
 *
 * @example
 * combineDateAndTime(new Date(2023, 03, 03), new Date(2022, 02, 02, 02, 02, 02)) => 2023-03-03 02:02:02
 */
const combineDateAndTime = (date: Date | undefined, time: Date | undefined): Date => {
  if (date && time) {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const hours = time.getHours()
    const minutes = time.getMinutes()
    const seconds = time.getSeconds()
    const milliseconds = time.getMilliseconds()
    return new Date(year, month, day, hours, minutes, seconds, milliseconds)
  } else if (!date && time) return time
  else if (date && !time) return date
  return new Date()
}

export {
  MONTH_NAMES,
  DAY_NAMES,
  prettyDateFromIso,
  prettyDateTimeFromIso,
  prettyDateFromObj,
  prettyDateTimeFromObj,
  isoDateFromPretty,
  isoDateFromObj,
  isoDateTimeFromObj,
  parseDate,
  partsFromIso,
  convertTimezone,
  getNumWeekdaysInMonth,
  addDays,
  getStartOfWeek,
  getDateRanges,
  isToday,
  diffInSeconds,
  combineDateAndTime,
  isDateInPast,
  convertToTimezone,
  convertBackToUtc,
}
