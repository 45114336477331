import moment from 'moment'
import { isNil } from 'ramda'

import { maybePluralize } from '~/helpers/string'

const prettyTimeFromObj = (date: Date): string => {
  let hours = '' + date.getHours()
  let minutes = '' + date.getMinutes()

  if (hours.length < 2) hours = '0' + hours
  if (minutes.length < 2) minutes = '0' + minutes

  return `${hours}:${minutes}`
}

const prettyTimeFromIso = (isoDateTime: string | null): string => {
  if (!isoDateTime) return ''

  const parts = isoDateTime.split(/[^0-9]/)
  return `${[parts[3]]}:${parts[4]}`
}

const prettyFromSeconds = (seconds: number): string => {
  const isNegative = seconds < 0
  const absoluteSeconds = Math.abs(seconds)

  const hours = Math.floor(absoluteSeconds / (60 * 60))
  const minutes = Math.floor((absoluteSeconds % (60 * 60)) / 60)

  const sHours = `${hours < 10 ? '0' : ''}${hours}`
  const sMinutes = `${minutes < 10 ? '0' : ''}${minutes}`

  return `${isNegative ? '-' : ''}${sHours}:${sMinutes}`
}

const prettyHoursMinutesFromSeconds = (second: number): string => {
  const hours = Math.floor(second / 3600)
  const minutes = Math.floor((second % 3600) / 60)
  const hourString = hours > 0 ? `${hours}h` : ''
  const minuteString = minutes > 0 ? `${minutes}m` : ''

  if (hours > 0 && minutes > 0) {
    return `${hourString} ${minuteString}`
  }

  if (hours > 0) {
    return `${hourString}`
  }

  return `${minuteString}`
}

/**
 * Convert milliseconds to minutes
 * @param millis
 */
const millisToMinutes = (millis: number): number => {
  return Math.floor(millis / 60000)
}

/**
 * Convert minutes to pretty time
 *
 * @example
 * 683 => 11:23
 */
const prettyFromMinutes = (minute: number): string => {
  return `${('0' + Math.floor(minute / 60)).slice(-2)}:${('0' + (minute % 60)).slice(
    -2
  )}`
}

const prettyDurationFromSeconds = (
  seconds: number,
  resolution = 2,
  pluralize = true
): string => {
  const isNegative = seconds < 0
  const absoluteSeconds = Math.abs(seconds)

  const weeks = Math.floor(absoluteSeconds / (3600 * 24 * 7))
  const days = Math.floor((absoluteSeconds % (3600 * 24 * 7)) / (3600 * 24))
  const hours = Math.floor((absoluteSeconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((absoluteSeconds % 3600) / 60)
  const sec = Math.floor(absoluteSeconds % 60)

  if (pluralize) {
    return `${isNegative ? '-' : ''}${[
      weeks ? maybePluralize(weeks, 'week') : null,
      days ? maybePluralize(days, 'day') : null,
      hours ? maybePluralize(hours, 'hr') : null,
      minutes ? maybePluralize(minutes, 'min') : null,
      sec ? maybePluralize(sec, 'sec') : null,
    ]
      .filter((v) => v !== null)
      .slice(0, resolution)
      .join(', ')}`
  }

  return `${isNegative ? '-' : ''}${[
    weeks ? `${weeks} week` : null,
    days ? `${days} day` : null,
    hours ? `${hours} hr` : null,
    minutes ? `${minutes} min` : null,
    sec ? `${sec} sec` : null,
  ]
    .filter((v) => v !== null)
    .slice(0, resolution)
    .join(', ')}`
}

const parseTime = (time: string): { hours: number | null; minutes: number | null } => {
  if (!time) return { hours: null, minutes: null }

  let hours, minutes

  if (time.includes(':')) {
    ;[hours, minutes] = time.replace(/[^0-9:]/g, '').split(':')
  } else {
    const onlyNumbers = time.replace(/[^0-9]/g, '')
    const numDigits = onlyNumbers.length

    if (numDigits < 3) {
      hours = onlyNumbers
      minutes = '0'
    } else {
      hours = onlyNumbers.substring(numDigits - 4, numDigits - 2)
      minutes = onlyNumbers.substring(numDigits - 2, numDigits)
    }
  }

  hours = parseInt(hours)
  minutes = parseInt(minutes)

  if (time.toUpperCase().includes('PM')) hours += 12

  if (
    isNil(hours) ||
    isNil(minutes) ||
    isNaN(hours) ||
    isNaN(minutes) ||
    hours >= 24 ||
    minutes >= 60
  ) {
    // hours or mins is missing/invalid, parsing most likely failed, reject
    hours = null
    minutes = null
  }

  return { hours, minutes }
}

const secondsFromPretty = (pretty: string): number | null => {
  const { hours, minutes } = parseTime(pretty)

  if (hours === null || minutes === null) return null

  return hours * 60 * 60 + minutes * 60
}

const isMultidayFromPretty = (start: string | null, end: string | null): boolean => {
  const startSeconds = start ? secondsFromPretty(start) : null
  const endSeconds = end ? secondsFromPretty(end) : null
  return startSeconds !== null && endSeconds !== null && startSeconds > endSeconds
}

const getNowPretty = (timeZone?: string): string =>
  new Date().toLocaleString('en-US', {
    timeZone,
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })

const isoTimeFromObj = (d: Date): string => {
  const hr = d.getHours()
  const min = d.getMinutes()
  const secs = d.getSeconds()

  const sHours = `${hr < 10 ? '0' : ''}${hr}`
  const sMinutes = `${min < 10 ? '0' : ''}${min}`
  const sSeconds = `${secs < 10 ? '0' : ''}${secs}`

  return `${sHours}:${sMinutes}:${sSeconds}`
}

const secondsElapsedForDay = (d: Date): number => {
  const hr = d.getHours()
  const min = d.getMinutes()
  const secs = d.getSeconds()

  return hr * 60 * 60 + min * 60 + secs
}

const getLocalTimeZone = () =>
  new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]

const secondsFromMinutes = (minutes: number) => minutes * 60

const isValidHour = (hour: string) => hour.match(/^(2[0-4]|[01]?[0-9])$/)
const isValidMinute = (hour: string) => hour.match(/^([0-5]?[0-9])$/)
const isValidTime = (time: string) => {
  const [hour, minute] = time.split(':')
  if (hour && minute) {
    const isValidFormat = isValidHour(hour) && isValidMinute(minute)
    if (isValidFormat) {
      if (hour === '24' && minute !== '00') {
        return false
      }
    }

    return !!isValidFormat
  }

  return false
}

const getLastWeek = (): Date => {
  return new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
}

const endOfDay = (date: string | Date): Date => {
  if (date instanceof Date) {
    return new Date(date.setHours(23, 59, 59, 59))
  } else {
    return new Date(new Date(date).setHours(23, 59, 59, 59))
  }
}

const startOfDay = (date: string | Date): Date => {
  if (date instanceof Date) {
    return new Date(date.setHours(0, 0, 0, 0))
  } else {
    return new Date(new Date(date).setHours(0, 0, 0, 0))
  }
}

const startOfDayMoment = (date: string): string => {
  const datetime = moment(date, 'MM/DD/YYYY HH:mm')

  // Update the time portion to 00:00 (midnight)
  datetime.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })

  return datetime.format('MM/DD/YYYY HH:mm')
}

const endOfDayMoment = (date: string): string => {
  const datetime = moment(date, 'MM/DD/YYYY HH:mm')

  datetime.set({
    hour: 23,
    minute: 59,
    second: 0,
    millisecond: 0,
  })

  return datetime.format('MM/DD/YYYY HH:mm')
}

/**
 * Helper that returns an array of available times given [start, end] minutes and interval
 *
 * @example
 * getAvailableTimes(0, 120, 30) => [
 *  { value: '0', label: '00:00' },
 *  { value: '30', label: '00:30' },
 *  { value: '60', label: '01:00' },
 *  { value: '90', label: '01:30' },
 *  { value: '120', label: '02:00' },
 *  ]
 *
 */

const getAvailableTimes = (
  startMinute: number,
  endMinute: number,
  interval: number
): Array<{ value: string; label: string }> => {
  const availableTimes = []
  for (let i = startMinute; i <= endMinute; i += interval) {
    availableTimes.push({ value: i.toString(), label: prettyFromMinutes(i) })
  }
  return availableTimes
}

export {
  prettyTimeFromObj,
  prettyTimeFromIso,
  prettyFromSeconds,
  prettyDurationFromSeconds,
  parseTime,
  secondsFromPretty,
  isMultidayFromPretty,
  getNowPretty,
  isoTimeFromObj,
  secondsElapsedForDay,
  secondsFromMinutes,
  getLocalTimeZone,
  isValidHour,
  isValidMinute,
  isValidTime,
  getLastWeek,
  prettyFromMinutes,
  getAvailableTimes,
  millisToMinutes,
  endOfDay,
  startOfDay,
  prettyHoursMinutesFromSeconds,
  startOfDayMoment,
  endOfDayMoment,
}
